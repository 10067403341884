import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastService } from '../../app/shared.service';
import { Subscription } from 'rxjs';
import { CMSServiceTermsAndConditions } from '../APIs/cms_content/termsAndConditions.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {
  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;
  loading: boolean = true;

  header: string = '';
  mainContent: SafeHtml = '';

  constructor(
    private toastService: ToastService,
    private cmsService: CMSServiceTermsAndConditions,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.cmsService.clearCache(); // Clear cache on initialization

    // Manage toast visibility
    const showToastStatus = sessionStorage.getItem('showToastClosed');
    const interval = setInterval(() => {
      if (showToastStatus === 'Open') {
        this.extraPaddingContent = true;
        clearInterval(interval);
      } else if (showToastStatus === 'Closed') {
        clearInterval(interval);
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);

    this.showToastSubscription = this.toastService.showToast$.subscribe(
      (showToast) => {
        this.extraPaddingContent = showToast;
      }
    );

    // Fetch IDs from JSON and use the content ID to fetch the content
    this.cmsService.getIdsData().subscribe({
      next: (idsData) => {
        const language = localStorage.getItem('siteLang') || 'en-US';
        const contentId = idsData[language]?.terms_and_conditions;
        if (contentId) {
          this.fetchContent(contentId);
        } else {
          this.loading = false;
        }
      },
      error: (error) => {
        this.loading = false;
      },
    });
  }

  private fetchContent(contentId: string): void {
    this.cmsService.fetchContentById(contentId).subscribe({
      next: (content) => {
        if (!content?.properties) {
          return;
        }
        this.mapDataToComponent(content);
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
      },
    });
  }

  ngOnDestroy(): void {
    this.showToastSubscription?.unsubscribe();
  }

  private mapDataToComponent(content: any): void {
    if (!content?.properties) {
      return;
    }

    const properties = content.properties;
    this.header = properties.header || '';
    this.mainContent = this.sanitizeHtml(properties.mainContent || '');
  }

  private sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
