import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CMSServiceGeneralInfo {

  constructor(private http: HttpClient) {}

  fetchSections(): Observable<any> {

    const payload = {
      filter: {
        contentType: 'general_info',
      },
      fetch: [
        "breadCrumb",
        "partName",
        "textAfterPartName",
        "partAssociatedGeneralInfo"
      ],
    };

    return this.http.post(`${environment.apiUrl}/Content/Search`, payload);
  }
}
