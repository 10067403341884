import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastService } from '../../app/shared.service';
import { Subscription } from 'rxjs';
import { CMSServiceAboutTheSupportGuide } from '../APIs/cms_content/aboutTheSupportGuide.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HelperService } from '../services/helper.service'; // Import HelperService

@Component({
  selector: 'app-about-the-support-guide',
  templateUrl: './about-the-support-guide.component.html',
  styleUrls: ['./about-the-support-guide.component.scss'],
})
export class AboutTheSupportGuideComponent implements OnInit, OnDestroy {
  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;

  header: string = '';
  mainContent: SafeHtml = '';
  buttonLabel: string = '';
  loading: boolean = true;

  constructor(
    private toastService: ToastService,
    private cmsService: CMSServiceAboutTheSupportGuide,
    private sanitizer: DomSanitizer,
    private helperService: HelperService // Inject HelperService
  ) {}

  ngOnInit(): void {
    this.cmsService.clearCache();

    const showToastStatus = sessionStorage.getItem('showToastClosed');
    const interval = setInterval(() => {
      if (showToastStatus === 'Open') {
        this.extraPaddingContent = true;
        clearInterval(interval);
      } else if (showToastStatus === 'Closed') {
        clearInterval(interval);
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);

    this.showToastSubscription = this.toastService.showToast$.subscribe((showToast) => {
      this.extraPaddingContent = showToast;
    });

    // Fetch IDs from JSON and use the content ID to fetch the content
    this.cmsService.getIdsData().subscribe({
      next: (idsData) => {
        const language = this.helperService.getLanguage(); // Use the HelperService to get language
        const contentId = idsData[language]?.about_the_fge_support_guide;
        if (contentId) {
          this.fetchContent(contentId);
        } else {
          this.loading = false;
        }
      },
      error: (error) => {
        this.loading = false;
      },
    });
  }

  private fetchContent(contentId: string): void {
    this.cmsService.fetchContentById(contentId).subscribe({
      next: (content) => {
        if (!content?.properties) {
          return;
        }
        this.mapCmsDataToComponent(content);
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
      },
    });
  }

  ngOnDestroy(): void {
    this.showToastSubscription?.unsubscribe();
  }

  private mapCmsDataToComponent(content: any): void {
    if (!content?.properties) {
      return;
    }

    const properties = content.properties;
    this.header = properties.header || '';
    this.mainContent = this.sanitizeHtml(properties.mainContent || '');
    this.buttonLabel = properties.buttonLabel || '';
  }

  private sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
