import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { RouterModule } from '@angular/router';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxPaginationModule} from 'ngx-pagination';
import { FooterService } from './shared.service';
import { NavService } from './shared.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { OnboardingModalComponent } from './onboarding-modal/onboarding-modal.component';
import { FGEInteractiveMapComponent } from './fge-interactive-map/fge-interactive-map.component';
import { MainFlowComponentsComponent } from './main-flow-components/main-flow-components.component';
import { VerificationComponent } from './verification/verification.component';
import { DetailedSectionComponent } from './fge-interactive-map/detailed-section/detailed-section.component';
import { AnimatedMapPart2Component } from './fge-interactive-map/animated-map-part2/animated-map-part2.component';
import { AnimatedMapPart1Component } from './fge-interactive-map/about-fge/about-fge.component';
import { SharedService } from './shared.service'; 
import { CardService } from './APIs/card.service'; 
import { LanguageService } from './APIs/allLanguages.service'; 
import { CategoriesComponent } from './main-flow-components/categories/categories.component';
import { SupportGuideComponent } from './main-flow-components/support-guide/support-guide.component';
import { SearchPageComponent } from './main-flow-components/search-page/search-page.component';
import { DetailsPageComponent } from './main-flow-components/details-page/details-page.component';
import { BookmarksPageComponent } from './main-flow-components/bookmarks-page/bookmarks-page.component';
import { DetailedSectionPart2Component } from './fge-interactive-map/detailed-section-part2/detailed-section-part2.component';
import { ModalDialogContent } from './fge-interactive-map/detailed-section/detailed-section.component';
import { ProfileComponent } from './main-flow-components/profile/profile.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { AuthConfigModule } from './auth-config/auth-config.module';
import { CallbackComponent } from './callback/callback.component';
import { AuthInterceptor } from './http-interceptors/auth.interceptor';
import { FooterComponent } from './footer/footer.component';
import { FacilitatorGuideComponent } from './facilitator-guide/facilitator-guide.component';
import { GetSupportComponent } from './get-support/get-support.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { FgeGoWebsiteTourComponent } from './fge-go-website-tour/fge-go-website-tour.component';
import { AboutTheSupportGuideComponent } from './about-the-support-guide/about-the-support-guide.component';
import { DialogContentMainDialog } from './onboarding-modal/onboarding-modal.component';
import { ErrorPagesComponent } from './error-pages/error-pages.component';
import { UnauthComponent } from './error-pages/unauth/unauth.component';
import { ServerComponent } from './error-pages/server/server.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { FormatHtmlPipe } from '../app/services/formatHtmlForTranslation'
import { SharedModule } from './shared.module';
import { environment } from './../environments/environment';
import {TitleStrategy} from '@angular/router';
import {CustomPageTitle} from '../app/services/customTitlesReplacement';
import { ToastService } from './shared.service';
import { ToastCommunicationService } from './shared.service';



export function HttpLoaderFactory(http:HttpClient){
  return new TranslateHttpLoader(http);
}

// import { MSAL_INSTANCE } from '@azure/msal-angular';

@NgModule({
  declarations: [
    AppComponent,    
    FGEInteractiveMapComponent,
    MainFlowComponentsComponent,
    AnimatedMapPart2Component,
    AnimatedMapPart1Component,
    CategoriesComponent,
    SupportGuideComponent,
    SearchPageComponent,
    DetailsPageComponent,
    BookmarksPageComponent,
    ModalDialogContent,
    ProfileComponent,
    CallbackComponent,
    FacilitatorGuideComponent,
    GetSupportComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    FgeGoWebsiteTourComponent,
    AboutTheSupportGuideComponent,
    ErrorPagesComponent,
    UnauthComponent,
    ServerComponent,
    // FormatHtmlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,    
    VerificationComponent,
    OnboardingModalComponent,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    RouterModule,
    DetailedSectionComponent,
    DetailedSectionPart2Component,
    MatInputModule,
    MatCardModule,
    FormsModule,
    MatTooltipModule,
    NgxPaginationModule,
    MatSelectModule,
    AuthConfigModule,
    FooterComponent,
    MatProgressSpinnerModule,
    SharedModule,
    TranslateModule.forRoot({
      loader:{
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }      
    ),

  ],
  providers: [
    SharedService,
    CardService,
    LanguageService,
    FooterService,
    NavService,
    DialogContentMainDialog,
    HttpClient,
    ToastService,
    ToastCommunicationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: TitleStrategy, useClass: CustomPageTitle }
    // provide: MSAL_INSTANCE,
    // useFactory: 
  ],

  
  bootstrap: [AppComponent]
  
})
export class AppModule { 
  
}
