import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { RouteService } from '../services/route.service';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  private resourceId!: number; // Private property to store resourceId

  constructor(private http: HttpClient, private routeService: RouteService) {}
  
  getCardById(resourceId: number): Observable<any> {
    this.resourceId = resourceId;

    if (this.resourceId === undefined) {
      throw new Error('resourceId is not set. Call setResourceId() first.');
    }

    return this.http.get<any>(`${environment.apiUrl}/resource/${this.resourceId}`);
  }

  downloadResource(resourceId: number): Observable<any> {
    this.resourceId = resourceId;

    if (this.resourceId === undefined) {
      throw new Error('resourceId is not set. Call setresourceId() first.');
    }

    return this.http.get<any>(`${environment.apiUrl}/resource/download/${this.resourceId}`);
  }

  async sendRatingWithComment(resourceId: number, ratingData: { ratingValue: number, comment: string }): Promise<HttpResponse<any> | undefined> {
    if (resourceId === undefined) {
      throw new Error('resourceId is not set. Call setResourceId() first.');
    }
  
    return await firstValueFrom(
      this.http.post(`${environment.apiUrl}/resource/${resourceId}/rate`, ratingData, { observe: 'response' })
    );
  }

  updateCardId(id: number): Observable<any> {  
    return this.http.post<any>(`${environment.apiUrl}/resource/${id}/view`, null);
  }
  

  async markResourceAsCompleted(resourceId: number): Promise<HttpResponse<any> | undefined> {
    if (resourceId === undefined) {
      throw new Error('resourceId is not set. Call setResourceId() first.');
    }
  
    try {
      const response = await firstValueFrom(
        this.http.post(`${environment.apiUrl}/resource/${resourceId}/complete`, null, { observe: 'response' })
      );
  
      if (response && response.status) {
        return response;
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  }
  
  
}
