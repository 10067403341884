<div *ngIf="loading" class="loader-container">
    <mat-spinner></mat-spinner>
  </div>

<div class="fullHeight" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
    <nav class="navigationWithPositionAbsolute">
        <a class="niceLineForFooterNav" [routerLink]="['/']">{{'AboutFGESupport.Home' | translate}} </a>
        <span> > </span>
        <span> {{ header }} </span>
    </nav>
    <div class="contentHolder">
        <h2 class="mb-30">{{ header }}</h2>
        <p [innerHTML]="mainContent"></p>
        <a class="linkButtomInTheAboutSupportGuide" mat-button routerLink="/support-guide">{{ buttonLabel }}</a>
    </div>
</div>
