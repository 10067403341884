<div class="fullHeight" [ngClass]="{'loader-active': isApiLoading, 'extraPaddingContent': extraPaddingContent}">
	<div *ngIf="isApiLoading" class="loader-container">
	  <mat-spinner></mat-spinner>
	</div>

<mat-drawer-container autosize="" *ngIf="!isApiLoading">
	<mat-drawer #drawer opened="false" mode="push" position="end">
		<mat-nav-list>
		  <section class="wrapSlideMenuHeader">
			<p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.JumpToSection" | translate}}</p>
			<a id="dnload-wkst" class="makeCertainSize" mat-raised-button href="{{'SharedItems.WorkSheetUrl' | translate}}" target="_blank" (click)="downloadGA4Push()">
			  {{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.ViewWorksheet" | translate}}
			</a>
		  </section>
	
		  <section class="wrapSlideMenuBody">
			<div *ngFor="let part of sideNavParts; let partIndex = index" class="wrapPartSections">
			  <!-- Part Title and Progress -->
			  <div class="headerDivInSlideMenuBody">
				<h4><b>{{'SharedItems.Part' | translate}} {{ part.partNumber }}{{'SharedItems.SemiColomn' | translate}} {{ part.partName }}</b></h4>
				<p>{{ getCompletedSectionsCount(partIndex) }} {{'SharedItems.of' | translate}} {{ getTotalSectionsCount(partIndex) }} {{'SharedItems.Completed' | translate}}</p>
			  </div>
		  
			  <!-- Navigation Section Dots and Links -->
			  <section class="mainNavSectionInSlideMenuBody">
				<div class="wrapDotsAndWords">
				  <!-- Navigation Dots -->
				  <div class="navigationDotsInSlideMenu">
					<ng-container *ngFor="let section of (sideNavSections[partIndex] || []); let i = index; let last = last">
						<a class="dots" [ngClass]="{ 'outerBorder': dynamicCurrentSectionIndexForSideNav[partIndex] === i }">
						  <div
							class="mini-dot"
							*ngIf="completedSectionsPerPart[partIndex] && completedSectionsPerPart[partIndex].length > i; else noData"
							[ngClass]="{
							  'activeDot': completedSectionsPerPart[partIndex][i],
							  'currentDot': dynamicCurrentSectionIndex === i
							}"
						  >
							<svg id="icon_-_checkmark_small" xmlns="http://www.w3.org/2000/svg" width="14.142" height="10.607" viewBox="0 0 14.142 10.607">
							  <rect id="Rectangle_10" width="2" height="13" rx="1" transform="translate(12.728) rotate(45)" fill="#fff" />
							  <rect id="Rectangle_11" width="2" height="7" rx="1" transform="translate(0 5.657) rotate(-45)" fill="#fff" />
							</svg>
						  </div>
						</a>
						<span *ngIf="!last"></span>
					  </ng-container>
				  </div>
		  
				  <!-- Navigation Text Links -->
				  <div class="textCloseToDotsInSideMenu">
					<ng-container *ngFor="let section of (sideNavSections[partIndex] || []); let i = index; let last = last">
					  <a (click)="closeSideMenu()">
						<span
						  class="wrapLinkInSideMenu"
						  (click)="scrollToSectionById(section.id)"
						  [routerLink]="['/onboarding/part', partIndex + 1]"
						  [fragment]="section.id"
						>
						  <p>{{ section.sectionName }}</p>
						  <span class="arrowheadInSideMenu">
							<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
							  <g id="icon_-_arrow_medium" transform="translate(7.778 15.556) rotate(-135)">
								<rect id="Rectangle_10" width="2" height="11" rx="1" fill="#6e6e6e" />
								<rect id="Rectangle_11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e" />
							  </g>
							</svg>
						  </span>
						</span>
					  </a>
					</ng-container>
				  </div>
				</div>
			  </section>
			</div>
		  </section>      
	
		  <!-- Close Side Menu Button -->
		  <button class="closeButtonInSideMenu" mat-raised-button (click)="closeSideMenu()">
			<div class="wrapTextAndSVGInSideMenu">
			  <p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.CloseMenu" | translate}}</p>
			  <svg id="icon_-_close_medium" xmlns="http://www.w3.org/2000/svg" width="15.557" height="15.556" viewBox="0 0 15.557 15.556">
				<rect id="Rectangle_10" width="2" height="20" rx="1" transform="translate(0 1.414) rotate(-45)" fill="#066fef"/>
				<rect id="Rectangle_11" width="2" height="20" rx="1" transform="translate(1.414 15.556) rotate(-135)" fill="#066fef"/>
			  </svg> 
			</div>
		  </button>
		</mat-nav-list>
	  </mat-drawer>
	<mat-drawer-content>

<div class="outerComponentColor min100vh" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
	<div class="wrapMapComponent">


<nav class="navigationForOnboardingComponent navigationWithPositionAbsolute">
	<a [routerLink]="['/']">{{"DetailedSectionOne.SectionOneViewSectionPage.TopNavLinkOne" | translate}}</a>
	<span> > </span>
	<a [routerLink]="['/onboarding/part', currentPartIndex + 1]">
		{{"DetailedSectionOne.SectionOneViewSectionPage.TopNavLinkTwo" | translate}} 
	</a>
	<span> > </span>
	<span id="sectionTitle">{{ currentSection.sectionName }}</span>
</nav>
<ng-container *ngFor="let section of sectionsFromApi">
	<section 
	  class="sectionForTheDetailedPage" 
	  [ngClass]="section.containerClass" 
	  *ngIf="sectionId === section.uniqueID">
	  
	  <div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" (click)="navigateBack()">
		  <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
			<g id="icon_-_arrow_medium" transform="translate(7.778) rotate(45)">
			  <rect width="2" height="11" rx="1" fill="#00095b" />
			  <rect width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b" />
			</g>
		  </svg>
		  {{ 'SharedItems.Back' | translate }}
		</a>
		<p class="sectionOnDetailedSection">
			{{'SharedItems.Section' | translate}} {{ calculateCurrentSectionNumber() }}
		</p>
		<h2>{{ currentSection.sectionName }}</h2>
		<p class="headingParagraph" [innerHTML]="currentSection.headingParagraph"></p>
		<p class="slightlyBiggerParagraph" [innerHTML]="currentSection.descriptionUnderHeadingParagraph"></p>
		<p class="slightlySmallerParagraph" [innerHTML]="currentSection.instructionText"></p>
		
		<div class="wrapCompleteButtonAndText">
		  <a (click)="navigateToNextSection()">
			<button mat-raised-button (click)="setSectionCompleted(sectionId)">
				{{'SharedItems.Complete' | translate}}
			</button>
		  </a>
		  <p [innerHTML]="currentSection.completionInstructionText"></p>
		</div>
	  </div>
  
	  <div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
	  
		<!-- Icons -->
		<div class="theSVGIcons">
			<ng-container *ngFor="let icon of currentSection.iconsOnThePage">
			  <!-- Regular Icon -->
			  <ng-container *ngIf="!icon.isProIcon">
				<svg 
				  [id]="icon.modalId" 
				  [ngClass]="icon.classNameForSVG" 
				  tabindex="0" 
				  (click)="stopDynamicAnimation($event, icon.id, currentSection)"
				  xmlns="http://www.w3.org/2000/svg" 
				  width="80" 
				  height="80" 
				  viewBox="0 0 80 80">
				  <g id="button_container_-_round" opacity="0.05" class="button-container-round">
					<circle cx="40" cy="40" r="40" fill="#066FEF"></circle>
				  </g>
				  <g id="button_container_-_round-2" transform="translate(13 13)" opacity="0.2" class="button-container-round-2">
					<circle cx="27" cy="27" r="27" fill="#066FEF"></circle>
				  </g>  
				  <g id="button_container_-_round-3" transform="translate(18 18)">
					<g fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"></circle>
					  <circle cx="22" cy="22" r="21.5" fill="none"></circle>
					</g>
				  </g>
				  <g id="icon_-_plus_medium" transform="translate(30 30)">
					<rect width="2" height="20" transform="translate(9)" fill="#fff"></rect>
					<rect width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"></rect>
				  </g>
				</svg>
			  </ng-container>
		  
			  <!-- Pro Icon -->
			  <ng-container *ngIf="icon.isProIcon">
				<svg 
				  [id]="icon.modalId" 
				  [ngClass]="icon.classNameForSVG" 
				  tabindex="0" 
				  (click)="stopDynamicAnimation($event, icon.id, currentSection)"
				  id="button_-_interactive" 
				  data-name="button - interactive" 
				  xmlns="http://www.w3.org/2000/svg" 
				  width="80" 
				  height="80" 
				  viewBox="0 0 80 80"
				  class="icon-bottom">
				  <g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round clickable">
					<circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"></circle>
				  </g>
				  <g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"></circle>
				  </g>
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"></circle>
					  <circle cx="22" cy="22" r="21.5" fill="none"></circle>
					</g>
				  </g>
				  <path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"></path>
				</svg>
			  </ng-container>
			</ng-container>
		  </div>		  
		  

		<div *ngFor="let image of currentSection.imagesOnThePage">
			<img [src]="image.source" [alt]="image.alt">
		</div>

	  
	  </div>
	</section>

	<div *ngIf="currentSection?.footerText" class="btm-therms" [innerHTML]="currentSection.footerText"></div>
</ng-container>

</div>



  
  
  

<!-- Footer -->
<footer class="footerForMapComponent">
	<div class="wrapfootercomponents">
	  <section>
		<p class="flexedP_inFooter {{'DetailedSectionOne.AnimatedMapFooter.ShorterLength' | translate}}">
		  <span class="blueBoldText">
			{{'SharedItems.Part' | translate}} {{ sideNavParts[currentPartIndex]?.partNumber }}{{'SharedItems.SemiColomn' | translate}}
		  </span>
		  <span class="solveSpacing">
			{{ sideNavParts[currentPartIndex]?.partName }}
		  </span>
		  <br class="showOnMobileOnly" /> <span class="dontShowOnMobile">|</span> 
		  <span class="lightBlueText longWordsShallNotPass">
			{{ currentSection.sectionName }}
		  </span>
		</p>
	  </section>

	  <div class="containerInMap">
		<ng-container *ngIf="sideNavSections && sideNavSections[currentPartIndex]?.length">
		  <ng-container *ngFor="let section of sideNavSections[currentPartIndex]; let i = index; let last = last">
		  <a class="dots"
		  	(click)="scrollToSectionById(section.id)"
			[routerLink]="['/onboarding/part', currentPartIndex + 1]" 
			[fragment]="section.id"
			[ngClass]="{ 'outerBorder': dynamicCurrentSectionIndex === i }">
			<div
			class="mini-dot"
			[ngClass]="{'activeDot': completedSectionsPerPart[currentPartIndex][i]}"
			></div>
		  </a>
		  <span *ngIf="!last"></span>
		  </ng-container>
		</ng-container>
	  </div>
	  
	  
	  <section>
		<span class="viewSections">{{"DetailedSectionOne.AnimatedMapFooter.RightSection" | translate}}</span>
		<button (click)="toggleMenu(); hideConfirmation(); closeToastOnClick();" mat-icon-button [class.active]="isActiveButton" aria-label="Example icon-button with menu icon">
		  <svg _ngcontent-ng-c875265465="" xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16"><g _ngcontent-ng-c875265465="" id="icon_-_hamburger_menu_-_default" data-name="icon - hamburger menu - default" transform="translate(6)"><rect _ngcontent-ng-c875265465="" id="Rectangle_11" data-name="Rectangle 11" width="2" height="18" rx="1" transform="translate(0 2) rotate(-90)" fill="#066FEF"></rect><rect _ngcontent-ng-c875265465="" id="Rectangle_169" data-name="Rectangle 169" width="2" height="18" rx="1" transform="translate(0 9) rotate(-90)" fill="#066FEF"></rect><rect _ngcontent-ng-c875265465="" id="Rectangle_170" data-name="Rectangle 170" width="2" height="18" rx="1" transform="translate(0 16) rotate(-90)" fill="#066FEF"></rect></g><g _ngcontent-ng-c875265465="" id="icon_-_hamburger_menu_-_default-2" data-name="icon - hamburger menu - default"><rect _ngcontent-ng-c875265465="" id="Rectangle_11-2" data-name="Rectangle 11" width="2" height="3" rx="1" transform="translate(0 2) rotate(-90)" fill="#0f0f0f"></rect><rect _ngcontent-ng-c875265465="" id="Rectangle_169-2" data-name="Rectangle 169" width="2" height="3" rx="1" transform="translate(0 9) rotate(-90)" fill="#0f0f0f"></rect><rect _ngcontent-ng-c875265465="" id="Rectangle_170-2" data-name="Rectangle 170" width="2" height="3" rx="1" transform="translate(0 16) rotate(-90)" fill="#0f0f0f"></rect></g></svg>
		</button>
	  </section>
	</div>
  </footer>
  
  <ng-template #noData>
	<div class="mini-dot inactiveDot"></div>
  </ng-template>
  
</div>

</mat-drawer-content>
</mat-drawer-container>