import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CMSServiceAboutTheSupportGuide {
  private cache = new Map<string, any>(); // Cache storage

  constructor(private http: HttpClient) {}

  // Fetch content by ID directly from the JSON file
  getIdsData(): Observable<any> {
    return this.http.get(environment.contentJsonPath); // Use the path from environment
  }

  // Fetch content by ID using the content API
  fetchContentById(contentId: string): Observable<any> {
    const fetchFields = ['header', 'mainContent', 'buttonLabel']; // Specify the required fields

    const requestBody = {
      id: contentId,
      fetch: fetchFields,
    };

    return this.http.post(`${environment.apiUrl}/Content`, requestBody);
  }

  // Clear cache method
  clearCache(): void {
    this.cache.clear(); // Clear the in-memory cache
  }
}
