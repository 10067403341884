<div class="modalForDetailedSection" tabindex="0">
    <!-- Close Icon -->
    <svg mat-dialog-close class="closeIconInModals" id="icon_-_close_medium" xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
      <rect id="Rectangle_10" width="2" height="20" rx="1" transform="translate(0 1.414) rotate(-45)" fill="#066fef" />
      <rect id="Rectangle_11" width="2" height="20" rx="1" transform="translate(1.414 15.556) rotate(-135)" fill="#066fef" />
    </svg>

  <!-- Modal Title -->
  <h2
    mat-dialog-title
    *ngIf="modalContent.header"
    [innerHTML]="modalContent.header"
  ></h2>

  <!-- Conditional Layout: Text on Left - Image on Right -->
  <mat-dialog-content *ngIf="isTextImageLayout">
    <section class="customFlexInModalWithImage">
      <div *ngIf="pages.length > 0" [innerHTML]="sanitizeAndHideImages(pages[activeSlide - 1])"></div>
      <ng-container *ngIf="getImageFromPage(pages[activeSlide - 1]) as image">
        <img [src]="image.src" [alt]="image.alt" />
      </ng-container>
    </section>
    <div *ngIf="modalContent.footer">
      <div class="btm-therms wrapTextInModal" [innerHTML]="modalContent.footer"></div>
    </div>
  </mat-dialog-content>

  <!-- Conditional Layout: Just Image -->
  <mat-dialog-content *ngIf="isImageLayout" class="justImageLayout">
    <section class="customFlexInModalWithImage">
      <ng-container *ngIf="getImageFromPage(pages[activeSlide - 1]) as image">
        <img [src]="image.src" [alt]="image.alt" />
      </ng-container>
    </section>
    <div *ngIf="modalContent.footer">
      <div class="btm-therms wrapTextInModal" [innerHTML]="modalContent.footer"></div>
    </div>
  </mat-dialog-content>

  <!-- Default Layout: Text Only -->
  <mat-dialog-content *ngIf="!isTextImageLayout && !isImageLayout">
      <!-- <div *ngIf="pages.length > 0" [innerHTML]="pages[activeSlide - 1]"></div> -->
      <div class="wrapTextInModal">
        <section>
          <!-- Display the current active page content -->
          <div *ngIf="pages.length > 0" [innerHTML]="pages[activeSlide - 1]"></div>
        </section>
        <div *ngIf="modalContent.footer">
          <div class="btm-therms wrapTextInModal" [innerHTML]="modalContent.footer"></div>
        </div>
      </div>
  </mat-dialog-content>

  <!-- Navigation buttons -->
  <mat-dialog-actions align="start" class="wrapBottomNavForCarousel" *ngIf="pages.length > 1">
    <div class="wrapNavigarionForCarousel">
      <span
        *ngFor="let page of pages; let idx = index"
        [class.activeDotForCarousel]="activeSlide === idx + 1"
        [class.inActiveDotForCarousel]="activeSlide !== idx + 1">
      </span>
    </div>
    <div class="wrapButtonsForCarousel">
      <button class="buttonInModal" mat-button (click)="goToPreviousSlide()" *ngIf="showPreviousButton()">{{'SharedItems.Previous' | translate}}</button>
      <button class="buttonInModal" mat-button *ngIf="showNextButton()" (click)="goToNextSlide()">{{'SharedItems.Next' | translate}}</button>
      <button class="buttonInModal" mat-button mat-dialog-close *ngIf="!showNextButton()">
        {{'SharedItems.GotIt' | translate}}
      </button>
    </div>
  </mat-dialog-actions>

      <!-- Close button -->
      <mat-dialog-actions align="end" *ngIf="pages.length <= 1">
        <button class="buttonInModal" mat-button mat-dialog-close>
          {{'SharedItems.GotIt' | translate}}
        </button>
      </mat-dialog-actions>
</div>
