import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CMSHomePageService {
  private idsDataSubject = new BehaviorSubject<any>(null); // Cache for db.json data
  private contentDataSubject = new BehaviorSubject<any>(null); // Cache for content API data

  constructor(private http: HttpClient) {}

  // Fetch db.json data, cache it if not already fetched
  getIdsData(): Observable<any> {
    if (!this.idsDataSubject.value) {
      return this.http.get(environment.contentJsonPath).pipe(
        tap((idsData) => this.idsDataSubject.next(idsData))
      );
    }
    return this.idsDataSubject.asObservable();
  }

  // Fetch content by ID, cache the response
  fetchContentById(contentId: string): Observable<any> {
    const fetchFields = [
      'headerLogo',
      'supportGuideLabel',
      'myBookmarksLabel',
      'marketIdentifierPopupMessage',
      'marketIdentifierPopupButton',
      'aboutFGELabel',
      'aboutFGETooltipText',
      'aboutFGETooltipButtonText',
      'aboutFGEText',
      'exploreFGEButton',
      'viewWorksheetLabel',
      'viewWorksheetLinkPDF',
      'aboutFGEImage',
      'fGESupportGuideLabel',
      'aboutTheSupportGuideLabel',
      'viewAllCategoriesLabel',
      'searchDefaultText',
      'footerLogo',
      'languageLabel',
      'fGEGOWebsiteTourLabel',
      'facilitatorGuideLabel',
      'getSupportLabel',
      'privacyPolicyLabel',
      'privacyPolicyLink',
      'termsAndConditionsLabel',
      'accessibilityLabel',
      'accessibilityLink',
      'footerTextAfterCopyrightAndYear',
    ];

    const requestBody = {
      id: contentId,
      fetch: fetchFields,
    };

    return this.http.post(`${environment.apiUrl}/Content`, requestBody).pipe(
      tap((contentData) => this.contentDataSubject.next(contentData))
    );
  }

  // Provide access to cached content data
  getContentData(): Observable<any> {
    return this.contentDataSubject.asObservable();
  }

  // Clear all caches
  clearCache(): void {
    this.idsDataSubject.next(null);
    this.contentDataSubject.next(null);
  }
}
