import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CardService } from '../../APIs/detailedCard.service';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { BookmarkService } from '../../APIs/bookmarked.service';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { SharedModule } from '../../shared.module';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../app/shared.service';
import { Subscription } from 'rxjs';

interface RelatedResource {
  resourceId: number;
  title: string;
  description: string;
  contentTypeId: number;
  contentType: string;
  categoryImage: string;
  resourceTypeId: string;
  isFavorite: boolean;
  category: string;
  categoryThumbnailUrl: string;
}

@Component({
  selector: 'app-details-page',
  templateUrl: './details-page.component.html',
  styleUrls: ['./details-page.component.scss']
})
export class DetailsPageComponent implements OnInit {
  cardTitle: string = '';
  cardUrl: string = '';
  cardDescription: string = '';
  cardImage: string = '';
  cardType: string = '';
  cardCategory: string = '';
  cardIsFavorite!: boolean;
  cardResourceId!: number;
  cardResourcePreviewURL: string = '';
  cardRating!: number;
  cardComment: string = '';
  highlightedStars: number = 0;  
  apiStatus: string = 'pending';

  cardRelatedResources: RelatedResource[] = [];

  showConfirmation = false;
  showConfirmation2 = false;
  cardIsFavoriteIndicator: boolean = false;
  cardResourceURL: string = '';

  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;

  constructor(private route: ActivatedRoute, private cardService: CardService, public dialog: MatDialog, private BookmarkService: BookmarkService, private http: HttpClient, private routeService: RouteService, private tagService: GA4Service, private toastService: ToastService) {}

  ngOnInit() {
    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);
  
    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });

    const mainLogo = document.getElementById("mainLogo");
    if (mainLogo) {
      mainLogo.focus();
    }  
    this.cardService.updateCardId(4);
    this.tagService.refreshPush();
    this.route.params.subscribe((params) => {
      this.loadCardDetails(Number(params['resourceId']));
      this.postViewAmount(Number(params['resourceId']));
    });
  }

  loadCardDetails(resourceId: number): void {
    if (!isNaN(resourceId)) {
      this.cardService.getCardById(resourceId).subscribe((resource) => {
        this.cardTitle = resource.title;
        this.cardUrl = resource.url;
        this.cardDescription = resource.description;
        this.cardImage = resource.thumbnailUrl;
        this.cardType = resource.contentType;
        this.cardCategory = resource.category;
        this.cardResourceId = resource.resourceId;
        this.cardResourcePreviewURL = resource.url;
        this.cardIsFavorite = resource.isFavorite;
        this.cardRating = resource.ratingValue;
        this.cardComment = resource.ratingComment;
        this.cardRelatedResources = resource.relatedResources;  
        this.apiStatus = 'success';      
      });
    }
  }

  postViewAmount(id: number): void {
    this.cardService.updateCardId(id).subscribe(
      (response) => {
      },
      (error) => {
      }
    );
  }

  openDialog(sectionIdForModal: string, title: any) {
    const dialogRef = this.dialog.open(DialogContentDetailsPage, {
      data: {
        sectionIdForModal,
        title,
        cardRating: this.cardRating,
        cardComment: this.cardComment,
        resourceId: this.cardResourceId,
        route: this.route,
        cardService: this.cardService,
        showConfirmation: this.showConfirmation,
        showConfirmation2: this.showConfirmation2,
        cardResourcePreviewURL: this.cardResourcePreviewURL,
        cardResourceURL: this.cardResourceURL = window.location.href
      }
    });
  
    dialogRef.afterClosed().subscribe(() => {

    });

    dialogRef.componentInstance.confirmationChanged.subscribe((confirmation: boolean) => {
      this.showConfirmation = confirmation;
    });

    dialogRef.componentInstance.confirmation2Changed.subscribe((confirmation: boolean) => {
      this.showConfirmation2 = confirmation; 
    });

    dialogRef.componentInstance.ratingSumitted.subscribe((rating: {ratingValue: number, comment: string}) => {
      this.cardRating = rating.ratingValue;
      this.cardComment = rating.comment;
    });

    this.highlightedStars = this.cardRating || 0;

  }

  hideConfirmation() {
    this.showConfirmation = false;
    this.showConfirmation2 = false;
  }

  onBookmarkClick(title:any) {
    this.BookmarkService.postBookmark(this.cardResourceId).subscribe(
      (response) => {
        if (!this.cardIsFavorite){
          this.tagService.resourceEngagement('bookmark', title);
        } else {
          this.tagService.resourceEngagement('remove bookmark', title);
        }
        this.cardIsFavorite = !this.cardIsFavorite;
      },
      (error) => {
        this.routeService.serverError(error);
      }
    );
  }

  onBookmarkIconClick(resource: RelatedResource, title: any) {
    const resourceId = resource.resourceId;

    this.BookmarkService.postBookmark(resourceId).subscribe(
      (response) => {
        if (!resource.isFavorite){
          this.tagService.resourceEngagement('bookmark', title);
        } else {
          this.tagService.resourceEngagement('remove bookmark', title);
        }
        resource.isFavorite = !resource.isFavorite;
      },
      (error) => {
      }
    );
  }

  downloadPDF(resourceId: number, engagementType: any, title: any, originalUrl: string): void {
    if (resourceId) {
      var indexStart = originalUrl.lastIndexOf('/') + 1;
      var endOfFile = originalUrl.lastIndexOf('.');
      var fileName = originalUrl.substring(indexStart, endOfFile);
      const pdfUrl = `${environment.apiUrl}/resource/download/${resourceId}`;

      this.http.get(pdfUrl, { responseType: 'blob' }).subscribe((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName + '.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    }
    this.tagService.resourceEngagement(engagementType, title);
  }

  showCount = 8;
  showStep = 8;
  
  resourceGA4Push(category: any, title: any, contentType: any, selectionType: any){
    this.tagService.resourceSelectedPush(category, title, contentType, selectionType);
  }

  previewGA4Push(engagementType: any, resourceName: any){
    this.tagService.resourceEngagement(engagementType, resourceName);
    // alert("Data sent");
  }

  async markAsCompleted(resourceId: number) {
    try {
      const response = await this.cardService.markResourceAsCompleted(resourceId);

      if (response?.status === 200) {
        // alert('success');
      } else {
        // alert('error');
      }
    } catch (error) {
    }
  }

  handleButtonClick(engagementType: string, resourceName: string, resourceId: number) {
    this.previewGA4Push(engagementType, resourceName);
    this.markAsCompleted(resourceId);
  }

  ngOnDestroy(): void {
    this.showToastSubscription?.unsubscribe();
  }

}



import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RouteService } from 'src/app/services/route.service';

@Component({
  selector: 'details-page-modal',
  templateUrl: 'details-page.modal.html',
  standalone: true,
  imports: [MatDialogModule, CommonModule, FormsModule, MatFormFieldModule, MatInputModule, SharedModule]
})
export class DialogContentDetailsPage{
  isStarClicked: boolean = false;
  showConfirmation: boolean = false;
  showConfirmation2: boolean = false;
  isDirty: boolean = false;
  valid: boolean = false;
  hasExistingRating: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: { 
      sectionIdForModal: string,
      title: string, 
      cardRating: number, 
      cardComment: string,
      cardResourcePreviewURL: string,
      resourceId: number,
      route: ActivatedRoute,
      cardService: CardService,
      dialogRef: MatDialogRef<DialogContentDetailsPage>,
      showConfirmation: boolean,
      showConfirmation2: boolean,
      cardResourceURL: string
    },
    private routeService: RouteService,
    private cardService: CardService,
    private route: ActivatedRoute,
    private tagService: GA4Service
  ) {
    this.showConfirmation = this.data.showConfirmation;
    this.showConfirmation2 = this.data.showConfirmation2;
    this.highlightedStars = this.data.cardRating;
    this.putComment = this.data.cardComment;
    this.cardResourcePreviewURLInModal = this.data.cardResourcePreviewURL
    this.data.cardResourceURL = this.data.cardResourceURL;
    this.hasExistingRating = this.data.cardRating !== null && this.data.cardRating !== undefined;
  }

  highlightedStars = this.data.cardRating;
  putComment = this.data.cardComment;
  cardResourcePreviewURLInModal = this.data.cardResourcePreviewURL;

  @Output() starClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() confirmationChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() ratingSumitted: EventEmitter<{ratingValue: number, comment: string}> = new EventEmitter<{ratingValue: number, comment: string}>();
  @Output() confirmation2Changed: EventEmitter<any> = new EventEmitter<boolean>();

  onStarClick(starIndex: number) {
    this.highlightedStars = starIndex;
    this.starClicked.emit(starIndex);

    this.isStarClicked = true;
    this.isDirty = true;
    // this.valid = this.highlightedStars != null && this.putComment !== '';
  }

  onCommentChange() {
    // this.isDirty = true;
    // this.valid = this.highlightedStars != null && this.putComment !== '';
  }

  submitRating() {
    if (this.data.resourceId === undefined) {
      this.routeService.error();
      return;
    }
  
    const ratingData = {
      ratingValue: this.highlightedStars,
      comment: this.putComment
    };
  
    this.data.cardService
      .sendRatingWithComment(this.data.resourceId, ratingData)
      .then((response) => {
        this.showConfirmation = true;
        this.confirmationChanged.emit(this.showConfirmation);
        this.ratingSumitted.emit(ratingData);
        this.tagService.resourceEngagementRating('rate', this.data.title, ratingData.ratingValue);
      })
      .catch((error) => {
        this.routeService.serverError(error);
      });
      
  }

  copyInputMessage(inputElement: HTMLInputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.tagService.resourceEngagement('share', this.data.title);
    this.showConfirmation2 = true;
    this.confirmation2Changed.emit(this.showConfirmation2);
  }

  shareGA4Push($event: KeyboardEvent){
    if(($event.ctrlKey || $event.metaKey) && $event.keyCode == 67){
      this.tagService.resourceEngagementKeybord('share-contc', this.data.title);
    }
    
  }

}


