import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
  })
  export class CMSService {
    constructor(private http: HttpClient) {}
  
    fetchSections(): Observable<any> {
  
      const payload = {
        filter: {
          contentType: 'section',
        },
        fetch: [
          "partAssociated",
          "sectionName",
          "sectionDescription",
          "images_for_section",
          "detailedHeadingParagraph",
          "detailedDescriptionUnderHeadingParagraph",
          "detailedInstructionText",
          "detailedCompletionInstructionText",
          "detailedImagesOnTheDetailedPage",
          "detailedModalSvg",
          "detailedSectionFooterContent"
        ],
      };
  
      return this.http.post(`${environment.apiUrl}/Content/Search`, payload);
    }
  
  }  