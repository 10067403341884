import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HelperService } from './services/helper.service';
import { environment } from 'src/environments/environment';
import { FooterService } from '../app/shared.service';
import { NavService } from '../app/shared.service';
import { Subscription, switchMap } from 'rxjs';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../app/APIs/profile.service';
import { ToastService } from '../app/shared.service';
import { ToastCommunicationService } from '../app/shared.service';
import { CMSHomePageService } from './APIs/cms_content/homePage.service';
import * as amplitude from '@amplitude/analytics-browser';
import { ampli } from 'src/ampli';
// import { MarketService } from './APIs/market.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ISG-FE';
  @ViewChild('headerModal') headerModal!: ElementRef;
  isTooltipVisible = false;
  showFooter = true;
  showNav = true;
  private footerSubscription: Subscription | undefined;
  private navSubscription: Subscription | undefined;
  siteLang: string = "";
  showToast: boolean = false;
  showExtraPadding: boolean = false;
  isToastClosed: string = "";
  contentId: string | null = null;

  headerLogoSrc: string = '';
  headerLogoAlt: string = '';
  supportGuideLabel: string = '';
  myBookmarksLabel: string = '';
  marketIdentifierPopupMessage: string = '';
  marketIdentifierPopupButton: string = '';

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private helperService: HelperService,
    private footerService: FooterService,
    private navService: NavService,
    private profileService: ProfileService,
    private tagService: GA4Service,
    private TranslateService: TranslateService,
    private toastService: ToastService,
    private toastCommunicationService: ToastCommunicationService,
    private cmsService: CMSHomePageService,
    // private marketService: MarketService
    ) 
   {

}

ngOnInit() {
  amplitude.init(environment.ampli_key, {
    autocapture: true,
  });
  ampli.load({ client: { apiKey: environment.ampli_key } });
  this.TranslateService.setDefaultLang('en-US');
  this.TranslateService.use(localStorage.getItem('siteLang') || 'en-US')

  const contentType = 'home_page';

  this.cmsService.clearCache();

  this.toastCommunicationService.closeToast$.subscribe(() => {
    this.showToast = false;
    this.showExtraPadding = false;
    this.toastService.setShowToast(false);
    this.isToastClosed = "Closed";
    sessionStorage.setItem("showToastClosed", this.isToastClosed);
  });

  let isToastShown = sessionStorage.getItem("showToastStatus");
  this.isToastClosed = sessionStorage.getItem("showToastClosed") || "Open";

  
    if (isToastShown !== "Seen" || this.isToastClosed!=="Closed") {
      this.showToast = true;
      this.showExtraPadding = true;
      sessionStorage.setItem("showToastStatus", "Seen");
      sessionStorage.setItem("showToastClosed", "Open")
    }

    this.profileService.getProfile().subscribe((data: any) =>{
      const userLang = data.language;
      this.siteLang = localStorage.getItem('siteLang') || userLang || 'en-US';
      localStorage.setItem('siteLang', this.siteLang);
      
      this.fetchContent(contentType);
      // this.marketService.marketChange$.subscribe(() => {
      //   this.onMarketSet(); // Fetch content when market changes
      // });    
    });

    //this.siteLang = localStorage.getItem('siteLang') || 'en-US';
    this.footerSubscription = this.footerService.showFooter$.subscribe((show) => {
      this.showFooter = show;
    });

    this.navSubscription = this.navService.showNav$.subscribe((show) => {
      this.showNav = show;
    });

    this.footerService.toggleFooterVisibility(false);
    this.navService.toggleNavVisibility(false);

    setTimeout(() => {
      this.footerService.toggleFooterVisibility(true);
      this.navService.toggleNavVisibility(true);
    }, 1000);

    if (environment.useADFS) {
      this.oidcSecurityService
        .checkAuth()
        .subscribe(({ isAuthenticated, userData, accessToken, idToken, configId }) => {
          if (isAuthenticated) {
            this.helperService.setToken(accessToken);
          }
        });
    }
    
  }

  onMarketSet(): void {
    this.siteLang = this.helperService.getMarket() ? `en-${this.helperService.getMarket()}` : 'en-US';
    this.fetchContent('home_page');
  }

  fetchContent(contentType: string): void {
    this.cmsService.getIdsData().pipe(
      switchMap((idsData) => {
        const contentId = idsData[this.siteLang]?.[contentType];
        if (!contentId) {
          return [];
        }
        return this.cmsService.fetchContentById(contentId);
      })
    ).subscribe({
      next: (content) => {
        this.mapDataToAppComponent(content);
      },
    });
  }

  private mapDataToAppComponent(content: any): void {
    const properties = content?.properties || {};
  
    const headerLogo = this.extractImageAttributes(properties.headerLogo);
    this.headerLogoSrc = headerLogo.src || '../assets/logos/FGEGo_Logo_new.png';
    this.headerLogoAlt = headerLogo.alt || 'FGE Logo';
    this.supportGuideLabel = properties.supportGuideLabel || '';
    this.myBookmarksLabel = properties.myBookmarksLabel || '';
    this.marketIdentifierPopupMessage = this.extractHtml(properties.marketIdentifierPopupMessage) || 'Default popup message';
    this.marketIdentifierPopupButton = properties.marketIdentifierPopupButton || 'Default button text';
  }
  

  private extractImageAttributes(html: string): { src: string; alt: string } {
    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(html, 'text/html');
    const img = parsedDoc.querySelector('img');
    return {
      src: img?.getAttribute('src') || '',
      alt: img?.getAttribute('alt') || '',
    };
  }

  private extractHtml(html: string | null | undefined): string {
    if (!html) return ''; // Handle undefined or null inputs
    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(html, 'text/html');
    const textContent = parsedDoc.body.textContent || '';
    return textContent;
  }

  hideConfirmation() {
    this.showToast = false;
    this.showExtraPadding = false;
    this.toastService.setShowToast(false);
    this.isToastClosed = "Closed";
    sessionStorage.setItem("showToastClosed", this.isToastClosed);  
  }

  toggleTooltip() {
    this.isTooltipVisible = !this.isTooltipVisible;
  }

  hideTooltip() {
    this.isTooltipVisible = false;
  }

  signout() {
    this.hideTooltip();

    this.helperService.removeToken();
    this.helperService.removeMarket();

    if (environment.useExternalAuth) {
      window.location.replace(`${window.location.origin}/logout`);
    } else if (environment.useADFS) {
      this.oidcSecurityService.logoff();
    } else {
      window.location.replace(`${window.location.origin}/${environment.suffixUrl}`);
    }

    this.tagService.signOutPush();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (this.isTooltipVisible && !this.headerModal.nativeElement.contains(event.target as Node)) {
      this.hideTooltip();
    }
  }
  
}
