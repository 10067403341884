import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Injectable } from '@angular/core';
import { LanguageService } from '../APIs/allLanguages.service';
import { FooterService } from '../shared.service';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { HelperService } from '../services/helper.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CMSHomePageService } from '../APIs/cms_content/homePage.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, FormsModule, MatSelectModule, ReactiveFormsModule, CommonModule, RouterModule, TranslateModule]
})
export class FooterComponent implements OnInit { 
  defaultLanguage = new FormControl(localStorage.getItem('siteLang'));
  languages: any[] = [];
  marketLanguages: any[] = [];
  siteLang: string = '';

  footerLogoSrc: string = '';
  footerLogoAlt: string = '';
  languageLabel: string = '';
  fGEGOWebsiteTourLabel: string = '';
  facilitatorGuideLabel: string = '';
  getSupportLabel: string = '';
  privacyPolicyLabel: string = '';
  privacyPolicyLink: string = '';
  termsAndConditionsLabel: string = '';
  accessibilityLabel: string = '';
  accessibilityLink: string = '';
  footerTextAfterCopyrightAndYear: string = '';

  constructor(private languageService: LanguageService, private footerService: FooterService, private tagService: GA4Service, private translateService: TranslateService, private helperService: HelperService, private cmsService: CMSHomePageService) {

  }
  showFooter = true;  

  
  
  getCurrentYear(): number {
    return new Date().getFullYear();
  }

  ngOnInit() {

    this.siteLang = localStorage.getItem('siteLang') || 'en-US';    

    this.languageService.getLanguages().subscribe((data: any) => {
      this.languages = data.languages;
      this.languages.forEach( (item, index) => {
        if(item.market.toLowerCase() === localStorage.getItem("market")?.toLowerCase()) {
         this.marketLanguages.push(this.languages[index]);            
        }        
      });
      this.defaultLanguage = new FormControl(localStorage.getItem('siteLang'));
    });

    this.footerService.showFooter$.subscribe((show) => {
      this.showFooter = show;
    });   
    
    this.fetchContent("home_page");
  }

  fetchContent(contentType: string): void {
    this.cmsService.getContentData().subscribe({
      next: (content) => {
        if (content) {
          this.mapCMSDataToFooter(content);
        }
      },
    });
  }

  private mapCMSDataToFooter(content: any): void {
    const properties = content?.properties || {};

    // Extract logo src and alt
    const footerLogo = this.extractImageAttributes(properties.footerLogo || '');
    this.footerLogoSrc = footerLogo.src || '../../assets/logos/FGEGo_Rev_Logo_new_white.png';
    this.footerLogoAlt = footerLogo.alt || 'Footer Logo';

    // Map other footer data
    this.languageLabel = properties.languageLabel || '';
    this.fGEGOWebsiteTourLabel = properties.fGEGOWebsiteTourLabel || '';
    this.facilitatorGuideLabel = properties.facilitatorGuideLabel || '';
    this.getSupportLabel = properties.getSupportLabel || '';
    this.privacyPolicyLabel = properties.privacyPolicyLabel || '';
    this.privacyPolicyLink = properties.privacyPolicyLink || '';
    this.termsAndConditionsLabel = properties.termsAndConditionsLabel || '';
    this.accessibilityLabel = properties.accessibilityLabel || '';
    this.accessibilityLink = properties.accessibilityLink || '';
    this.footerTextAfterCopyrightAndYear = properties.footerTextAfterCopyrightAndYear || 'Ford Motor Company';
  }

  private extractImageAttributes(html: string): { src: string; alt: string } {
    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(html, 'text/html');
    const img = parsedDoc.querySelector('img');
    return {
      src: img?.getAttribute('src') || '',
      alt: img?.getAttribute('alt') || '',
    };
  }
  

  changeLang(lang: any){
    const market: any = this.helperService.getMarket();
    const selLang = lang.value;
    localStorage.setItem("siteLang", selLang);
    this.translateService.use(selLang);
    this.languageService.updateUserLanguage(selLang).subscribe((response) => {      
      window.location.reload();
    });    
  }

  footerGA4Push(event: any){
    var url = event.currentTarget.href;
    var title = event.currentTarget.innerText;
    this.tagService.footerClickPush(title, url);
  }

  
}
