import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'formatHtml',
})
export class FormatHtmlPipe implements PipeTransform {
  private tagRules: { [key: string]: { open: string; close?: string, dynamicAttributes?: { [key: string]: string } } } = {
    italic: { open: '<i>', close: '</i>' },
    breakLine: { open: '<br />' },
    sup: { open: '<sup>', close: '</sup>' },
    bold: { open: '<b>', close: '</b>' },
    ul: { open: '<ul>', close: '</ul>' },
    li: { open: '<li>', close: '</li>' },
    em: { open: '<em>', close: '</em>' },
    u: { open: '<u>', close: '</u>' },
    span: { open: '<span>', close: '</span>' },
    a: { open: 'a', close: '</a>', dynamicAttributes: { 'target': '_blank', 'href': '' } },
  };

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string | null | undefined): SafeHtml {
    if (!value) {
      return '';
    }

    let formattedValue = value;

    for (const tag in this.tagRules) {
      const rule = this.tagRules[tag];
      
      // Replace opening tags
      formattedValue = formattedValue.replace(new RegExp(`{${tag}}`, 'g'), rule.open);

      // Handle special case for the <a> tag with dynamic attributes
      if (tag === 'a' && rule.dynamicAttributes) {
        // Find href content between single or double quotes in the input string
        const hrefMatches = value.match(new RegExp(`{${tag}\\s+href=(?:'([^']*)'|"([^"]*)")}`));

        if (hrefMatches) {
          // Use the first non-empty match for href
          rule.dynamicAttributes['href'] = hrefMatches[1] || hrefMatches[2] || '';
        }

        const attributes = Object.entries(rule.dynamicAttributes).map(([key, val]) => `${key}="${val}"`).join(' ');
        formattedValue = formattedValue.replace(new RegExp(`{${tag}.*?}`), `<${rule.open} ${attributes}>`);
      }

      // Replace closing tags if applicable
      if (rule.close) {
        formattedValue = formattedValue.replace(new RegExp(`{/${tag}}`, 'g'), rule.close);
      }
    }

    return this.sanitizer.bypassSecurityTrustHtml(formattedValue);
  }
}
