import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CMSServiceModalInDetailedSection {

  constructor(private http: HttpClient) {}

  fetchModals(modalSectionNumber: string, modalPartNumber: string): Observable<any> {
    const payload = {
        filter: {
            contentType: 'modal_for_detailed_page',
            modalSectionNumber: modalSectionNumber,
            modalPartNumber: modalPartNumber,
        },
        fetch: [
            "header",
            "mainContent",
            "footer",
            "modalSectionNumber"
        ],
    };

    return this.http.post(`${environment.apiUrl}/Content/Search`, payload);
}

  
}
