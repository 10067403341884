import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

//import { FGEInteractiveMapComponent } from './fge-interactive-map/fge-interactive-map.component';
import { AnimatedMapPart1Component } from './fge-interactive-map/about-fge/about-fge.component';
import { DetailedSectionComponent } from './fge-interactive-map/detailed-section/detailed-section.component';
import { AnimatedMapPart2Component } from './fge-interactive-map/animated-map-part2/animated-map-part2.component';
import { DetailedSectionPart2Component } from './fge-interactive-map/detailed-section-part2/detailed-section-part2.component';
import { MainFlowComponentsComponent } from './main-flow-components/main-flow-components.component';
//import { CategoriesComponent } from './main-flow-components/categories/categories.component';
import { SupportGuideComponent } from './main-flow-components/support-guide/support-guide.component';
import { DetailsPageComponent } from './main-flow-components/details-page/details-page.component';
import { BookmarksPageComponent } from './main-flow-components/bookmarks-page/bookmarks-page.component';
import { SearchPageComponent } from './main-flow-components/search-page/search-page.component';
import { ProfileComponent } from './main-flow-components/profile/profile.component';
import { CallbackComponent } from './callback/callback.component';
import { MasterGuard } from './guards/master.guard';
import { FacilitatorGuideComponent } from './facilitator-guide/facilitator-guide.component'
import { GetSupportComponent } from './get-support/get-support.component'
import { FgeGoWebsiteTourComponent } from './fge-go-website-tour/fge-go-website-tour.component'
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component'
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component'
import { AboutTheSupportGuideComponent } from './about-the-support-guide/about-the-support-guide.component'
import { ErrorPagesComponent } from './error-pages/error-pages.component'
import { UnauthComponent } from './error-pages/unauth/unauth.component'
import { ServerComponent } from './error-pages/server/server.component'
import { VerificationComponent } from './verification/verification.component'
import { InfoPromptGuard } from './guards/info-prompt.guard';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 0]
};

const routes: Routes = [
  // {
  //   path: 'fge-interactive-map',
  //   component: FGEInteractiveMapComponent,
  //   title: 'FGE Interactive Map'
  // },

  // {
  //   path: 'onboarding/part-1',
  //   canActivate: [MasterGuard],
  //   component: AnimatedMapPart1Component,
  //   title: 'PageTitles.AboutFGE'
  // },

  {
    path: 'onboarding/part/:partIndex',
    canActivate: [MasterGuard],
    component: AnimatedMapPart1Component,
    title: 'PageTitles.AboutFGE',
    pathMatch: 'full'
  },
  {
    path: 'onboarding/part/:partIndex/:sectionId',
    canActivate: [MasterGuard],
    component: DetailedSectionComponent,
    title: 'PageTitles.DetailedSection',
    pathMatch: 'full'
  },
  // {
  //   path: 'onboarding/part-1/:sectionId',
  //   canActivate: [MasterGuard],
  //   component: DetailedSectionComponent,
  //   title: 'PageTitles.DetailedSection'
  // },
  // {
  //   path: 'onboarding/part-2',
  //   canActivate: [MasterGuard],
  //   component: AnimatedMapPart2Component,
  //   title: 'PageTitles.AboutFGEPart2'
  // },
  // {
  //   path: 'onboarding/part-2/:sectionId',
  //   canActivate: [MasterGuard],
  //   component: DetailedSectionPart2Component,
  //   title: 'PageTitles.DetailedSectionPart2'
  // },
  {
    path: '',
    canActivate: [MasterGuard],
    component: MainFlowComponentsComponent,
    title: 'PageTitles.Homepage'
  },
  // {
  //   path: 'main-flow-components/categories',
  //   canActivate: [MasterGuard],
  //   component: CategoriesComponent,
  //   title: 'All Categories'
  // },
  {
    path: 'support-guide',
    canActivate: [MasterGuard],
    component: SupportGuideComponent,
    title: 'PageTitles.SupportGuide'
  },  
  {
    path: 'bookmarks',
    canActivate: [MasterGuard],
    component: BookmarksPageComponent,
    title: 'PageTitles.MyBookmarks'
  },
  {
    path: 'profile',
    canActivate: [MasterGuard],
    component: ProfileComponent,
    title: 'PageTitles.MyProfile'
  },
  {
    path: 'support-guide/search',
    canActivate: [MasterGuard],
    component: SearchPageComponent,
    title: 'PageTitles.SearchPage'
  },
  {
    path: 'support-guide/details/:resourceId',
    canActivate: [MasterGuard],
    component: DetailsPageComponent,
    title: 'PageTitles.DetailSupport'
  },
  {
    path: 'facilitator-guide',
    canActivate: [MasterGuard],
    component: FacilitatorGuideComponent,
    title: 'PageTitles.FacilitatorGuide'
  },
  {
    path: 'get-support',
    canActivate: [MasterGuard],
    component: GetSupportComponent,
    title: 'PageTitles.GetSupport'
  },
  {
    path: 'fge-go-website-tour',
    canActivate: [MasterGuard],
    component: FgeGoWebsiteTourComponent,
    title: 'PageTitles.FGEGOWebsiteTour'
  },
  {
    path: 'privacy-policy',
    canActivate: [MasterGuard],
    component: PrivacyPolicyComponent,
    title: 'PageTitles.PrivacyPolicy'
  },
  {
    path: 'terms-and-conditions',
    canActivate: [MasterGuard],
    component: TermsAndConditionsComponent,
    title: 'PageTitles.TermsConditions'
  },
  {
    path: 'about-the-support-guide',
    canActivate: [MasterGuard],
    component: AboutTheSupportGuideComponent,
    title: 'PageTitles.AboutTheSupportGuide'
  },
  {
    path: 'error',
    canActivate: [MasterGuard],
    component: ErrorPagesComponent,
    title: 'PageTitles.Error'
  },
  {
    path: 'error/unauthorized',
    canActivate: [MasterGuard],
    component: UnauthComponent,
    title: 'PageTitles.Error'
  },
  {
    path: 'error/server',
    canActivate: [MasterGuard],
    component: ServerComponent,
    title: 'PageTitles.Error'
  },
  {
    path: 'verification',
    canActivate: [InfoPromptGuard],
    component: VerificationComponent,
    title: 'PageTitles.Verification'
  },
  {
    //this will change to path: 'callback' once ADFS is updated
    path: 'callback/:token',
    component: CallbackComponent
  },
  { 
    path: '**', 
    redirectTo: 'error' 
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
