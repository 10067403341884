import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HelperService } from '../services/helper.service';
import { ToastService } from '../../app/shared.service';
import { Subscription } from 'rxjs';
import { CMSHomePageService } from '../APIs/cms_content/homePage.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-verification',
  templateUrl: 'verification.component.html',
  styleUrls: ['./verification.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, MatInputModule, FormsModule, CommonModule]
})
export class VerificationComponent implements OnInit{
  token: string | null = "";
  market: string | null = "";
  isSetButtonDisabled: boolean = true;
  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;
  contentId: string | null = null;

  constructor(public dialog: MatDialog, private router: Router,
    private helperService: HelperService, private toastService: ToastService, 
    private cmsService: CMSHomePageService
  ) {}

  ngOnInit(): void {
    this.cmsService.clearCache();
    this.token = this.helperService.getToken();
    this.market = this.helperService.getMarket();

    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);
  
    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });
  }

  validateInput() {  
    this.isSetButtonDisabled = !this.token || this.token === '' || !this.market || this.market === '';
  }
  
  set(): void {
    // Determine the market and siteLang based on input
    let updatedMarket = this.market || '';
    let updatedToken = this.token || '';
  
    // Apply the rule for en-GB and UK
    if (updatedMarket.toUpperCase() === 'UK') {
      updatedMarket = 'UK'; // Ensure market is set to 'UK'
      localStorage.setItem('siteLang', 'en-GB'); // Set language explicitly to 'en-GB'
    } else {
      localStorage.setItem('siteLang', `en-${updatedMarket}`);
    }
  
    // Save token and market
    this.helperService.setToken(updatedToken);
    this.helperService.setMarket(updatedMarket);
  
    // Update localStorage for market
    localStorage.setItem('market', updatedMarket);
  
    // Fetch the CMS content based on the market and language
    const siteLang = localStorage.getItem('siteLang') || 'en-US';
    const contentType = 'home_page';
  
    this.cmsService.getIdsData().subscribe({
      next: (idsData) => {
        const contentId = idsData[siteLang]?.[contentType];
        if (!contentId) {
          return;
        }
        this.cmsService.fetchContentById(contentId).subscribe({
          next: (content) => {
            this.router.navigateByUrl('/');
          },
          error: (error) => {
          },
        });
      },
      error: (error) => {
      },
    });
  }
  
  

  ngOnDestroy(): void {
    this.showToastSubscription?.unsubscribe();
  }
}